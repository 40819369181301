import React, { useState } from "react";
import "./App.css";
import Header from "./components/Layout/Header";
import Disclaimer from "./components/Disclaimer";
import Tooltip from "./components/Tooltip";
import { tooltipMessages } from "./constants/tooltips";

import { CalculationResult, FormData, FormErrors, ErrorState } from "./types";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001";
const API_KEY = process.env.REACT_APP_API_KEY || "";

function getPaymentPeriodLabel(frequency: string): string {
  switch (frequency) {
    case "monthly":
      return "Monthly payments";
    case "bi-weekly":
      return "Bi-weekly payments";
    case "accelerated-bi-weekly":
      return "Accelerated bi-weekly payments";
    default:
      return "Payments";
  }
}

function App() {
  const [activeTooltip, setActiveTooltip] = useState<string | null>(null);

  const formatCurrency = (value: string, skipFormat: boolean = false): string => {
    if (!value) return "";

    // Remove all non-numeric characters except decimal
    const cleanValue = value.replace(/[^0-9.]/g, "");

    if (skipFormat) return cleanValue;

    // Split on decimal point
    const parts = cleanValue.split(".");
    const wholePart = parts[0] || "0";

    // Format whole part with commas
    const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Only add decimal part if it exists in input
    if (parts.length > 1) {
      const decimalPart = parts[1].slice(0, 2); // Limit to 2 decimal places
      return `${formattedWholePart}.${decimalPart}`;
    }

    return formattedWholePart;
  };
  const [remainingBalance, setRemainingBalance] = useState("400,000.00");
  const [remainingTerm, setRemainingTerm] = useState("48");
  const [currentInterestRate, setCurrentInterestRate] = useState("5.99");
  const [breakingInterestRate, setBreakingInterestRate] = useState("6.99");
  const [currentProviderPrimeRate, setCurrentProviderPrimeRate] = useState("6.54");
  const [periodicPayment, setPeriodicPayment] = useState("1179.40");
  const [paymentFrequency, setPaymentFrequency] = useState("bi-weekly");
  const [newInterestRate, setNewInterestRate] = useState("3.99");
  const [amortizationPeriod, setAmortizationPeriod] = useState("25");
  // const [compoundingPeriod, setCompoundingPeriod] = useState('semi-annually');
  const [result, setResult] = useState<CalculationResult | null>(null);
  const [error, setError] = useState<ErrorState | null>(null);
  const [fieldErrors, setFieldErrors] = useState<FormErrors>({});

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setResult(null);
    setFieldErrors({});
    try {
      // Calculate penalty
      const response = await fetch(`${API_URL}/api/calculate-penalty`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": API_KEY,
        },
        body: JSON.stringify({
          remainingBalance: parseFloat(remainingBalance.replace(/,/g, "")),
          remainingTerm: parseInt(remainingTerm),
          currentInterestRate: parseFloat(currentInterestRate),
          breakingInterestRate: parseFloat(breakingInterestRate),
          currentProviderPrimeRate: parseFloat(currentProviderPrimeRate),
          periodicPayment: parseFloat(periodicPayment),
          paymentFrequency,
          newInterestRate: parseFloat(newInterestRate),
          amortizationPeriod: parseInt(amortizationPeriod),
          // compoundingPeriod,
        }),
      });
      if (!response.ok) {
        if (response.status === 403) {
          throw new Error("Invalid API key. Please check your API key and try again.");
        }
        const errorData = await response.json();
        if (errorData.field) {
          setFieldErrors({ [errorData.field]: errorData.error });
        } else {
          throw new Error(errorData.error || "An error occurred");
        }
        return;
      }
      const data: CalculationResult = await response.json();
      setResult(data);
    } catch (error) {
      console.error("Error:", error);
      setError({
        message: error instanceof Error ? error.message : "An unexpected error occurred",
      });
    }
  };

  return (
    <div className="App">
      <Header />
      <div className="calculator-layout">
        <div className="calculator-form-container">
          {error && <div className="error-banner">Error: {error.message}</div>}
          <form onSubmit={handleSubmit} className="form-container">
            <div className="form-grid">
              <div className="form-group">
                <div className="label-container">
                  <label className="label-text" htmlFor="remainingBalance">
                    Remaining Mortgage Balance ($)
                  </label>
                  <span
                    className="tooltip-trigger"
                    onClick={() =>
                      setActiveTooltip(
                        activeTooltip === "remainingBalance" ? null : "remainingBalance"
                      )
                    }
                    aria-label="Show more information"
                  >
                    What's this?
                    {activeTooltip === "remainingBalance" && (
                      <Tooltip
                        content={tooltipMessages.remainingBalance}
                        isVisible={true}
                        onClose={() => setActiveTooltip(null)}
                      />
                    )}
                  </span>
                </div>
                <div className="input-group">
                  <span className="input-prefix">$</span>
                  <input
                    type="text"
                    id="remainingBalance"
                    className="currency-input"
                    value={remainingBalance}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      const isDecimalDelete =
                        remainingBalance.includes(".") &&
                        !newValue.includes(".") &&
                        newValue.length < remainingBalance.length;

                      const formatted = formatCurrency(newValue, isDecimalDelete);
                      if (formatted || formatted === "") {
                        setRemainingBalance(formatted);
                      }
                    }}
                    onKeyPress={(e) => {
                      const char = String.fromCharCode(e.charCode);
                      const pattern = /[0-9.]/;
                      if (
                        !pattern.test(char) ||
                        (e.currentTarget.value.includes(".") && char === ".")
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={(e) => {
                      const value = e.target.value;
                      if (value) {
                        const formatted = formatCurrency(value);
                        setRemainingBalance(formatted);
                      } else {
                        setRemainingBalance(remainingBalance);
                      }
                    }}
                    inputMode="decimal"
                    placeholder="0.00"
                    required
                  />
                </div>
                {fieldErrors.remainingBalance && (
                  <div className="error-message">{fieldErrors.remainingBalance}</div>
                )}
              </div>
              <div className="form-group">
                <div className="label-container">
                  <label className="label-text" htmlFor="remainingTerm">
                    Remaining Term (months):
                  </label>
                  <span
                    className="tooltip-trigger"
                    onClick={() =>
                      setActiveTooltip(activeTooltip === "remainingTerm" ? null : "remainingTerm")
                    }
                    aria-label="Show more information"
                  >
                    What's this?
                    {activeTooltip === "remainingTerm" && (
                      <Tooltip
                        content={tooltipMessages.remainingTerm}
                        isVisible={true}
                        onClose={() => setActiveTooltip(null)}
                      />
                    )}
                  </span>
                </div>
                <input
                  type="number"
                  id="remainingTerm"
                  value={remainingTerm}
                  onChange={(e) => setRemainingTerm(e.target.value)}
                  required
                  min="1"
                />
              </div>
              <div className="form-group">
                <div className="label-container">
                  <label className="label-text" htmlFor="currentInterestRate">
                    Discounted Interest Rate (%):
                  </label>
                  <span
                    className="tooltip-trigger"
                    onClick={() =>
                      setActiveTooltip(
                        activeTooltip === "currentInterestRate" ? null : "currentInterestRate"
                      )
                    }
                    aria-label="Show more information"
                  >
                    What's this?
                    {activeTooltip === "currentInterestRate" && (
                      <Tooltip
                        content={tooltipMessages.currentInterestRate}
                        isVisible={true}
                        onClose={() => setActiveTooltip(null)}
                      />
                    )}
                  </span>
                </div>
                <div className="input-group">
                  <input
                    type="number"
                    id="currentInterestRate"
                    className="percentage-input"
                    value={currentInterestRate}
                    onChange={(e) => setCurrentInterestRate(e.target.value)}
                    step="0.01"
                    required
                    min="0"
                    max="100"
                  />
                  <span className="input-suffix">%</span>
                </div>
              </div>
              <div className="form-group">
                <div className="label-container">
                  <label className="label-text" htmlFor="breakingInterestRate">
                    Posted Interest Rate (%):
                  </label>
                  <span
                    className="tooltip-trigger"
                    onClick={() =>
                      setActiveTooltip(
                        activeTooltip === "breakingInterestRate" ? null : "breakingInterestRate"
                      )
                    }
                    aria-label="Show more information"
                  >
                    What's this?
                    {activeTooltip === "breakingInterestRate" && (
                      <Tooltip
                        content={tooltipMessages.breakingInterestRate}
                        isVisible={true}
                        onClose={() => setActiveTooltip(null)}
                      />
                    )}
                  </span>
                </div>
                <div className="input-group">
                  <input
                    type="number"
                    id="breakingInterestRate"
                    className="percentage-input"
                    value={breakingInterestRate}
                    onChange={(e) => setBreakingInterestRate(e.target.value)}
                    step="0.01"
                    required
                    min="0"
                    max="100"
                  />
                  <span className="input-suffix">%</span>
                </div>
              </div>
              <div className="form-group">
                <div className="label-container">
                  <label className="label-text" htmlFor="currentProviderPrimeRate">
                    Current Provider Posted Rate (%):
                  </label>
                  <span
                    className="tooltip-trigger"
                    onClick={() =>
                      setActiveTooltip(
                        activeTooltip === "currentProviderPrimeRate"
                          ? null
                          : "currentProviderPrimeRate"
                      )
                    }
                    aria-label="Show more information"
                  >
                    What's this?
                    {activeTooltip === "currentProviderPrimeRate" && (
                      <Tooltip
                        content={tooltipMessages.currentProviderPrimeRate}
                        isVisible={true}
                        onClose={() => setActiveTooltip(null)}
                      />
                    )}
                  </span>
                </div>
                <div className="input-group">
                  <input
                    type="number"
                    id="currentProviderPrimeRate"
                    className="percentage-input"
                    value={currentProviderPrimeRate}
                    onChange={(e) => setCurrentProviderPrimeRate(e.target.value)}
                    step="0.01"
                    required
                    min="0"
                    max="100"
                  />
                  <span className="input-suffix">%</span>
                </div>
              </div>
              <div className="form-group">
                <div className="label-container">
                  <label className="label-text" htmlFor="periodicPayment">
                    Periodic Payment Amount ($):
                  </label>
                  <span
                    className="tooltip-trigger"
                    onClick={() =>
                      setActiveTooltip(
                        activeTooltip === "periodicPayment" ? null : "periodicPayment"
                      )
                    }
                    aria-label="Show more information"
                  >
                    What's this?
                    {activeTooltip === "periodicPayment" && (
                      <Tooltip
                        content={tooltipMessages.periodicPayment}
                        isVisible={true}
                        onClose={() => setActiveTooltip(null)}
                      />
                    )}
                  </span>
                </div>
                <div className="input-group">
                  <span className="input-prefix">$</span>
                  <input
                    type="text"
                    id="periodicPayment"
                    className="currency-input"
                    value={periodicPayment}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      const isDecimalDelete =
                        periodicPayment.includes(".") &&
                        !newValue.includes(".") &&
                        newValue.length < periodicPayment.length;

                      const formatted = formatCurrency(newValue, isDecimalDelete);
                      if (formatted || formatted === "") {
                        setPeriodicPayment(formatted);
                      }
                    }}
                    onKeyPress={(e) => {
                      const char = String.fromCharCode(e.charCode);
                      const pattern = /[0-9.]/;
                      if (
                        !pattern.test(char) ||
                        (e.currentTarget.value.includes(".") && char === ".")
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={(e) => {
                      const value = e.target.value;
                      if (value) {
                        const formatted = formatCurrency(value);
                        setPeriodicPayment(formatted);
                      } else {
                        setPeriodicPayment(periodicPayment);
                      }
                    }}
                    inputMode="decimal"
                    placeholder="0.00"
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="label-container">
                  <label className="label-text" htmlFor="paymentFrequency">
                    Payment Frequency:
                  </label>
                  <span
                    className="tooltip-trigger"
                    onClick={() =>
                      setActiveTooltip(
                        activeTooltip === "paymentFrequency" ? null : "paymentFrequency"
                      )
                    }
                    aria-label="Show more information"
                  >
                    What's this?
                    {activeTooltip === "paymentFrequency" && (
                      <Tooltip
                        content={tooltipMessages.paymentFrequency}
                        isVisible={true}
                        onClose={() => setActiveTooltip(null)}
                      />
                    )}
                  </span>
                </div>
                <select
                  id="paymentFrequency"
                  value={paymentFrequency}
                  onChange={(e) => setPaymentFrequency(e.target.value)}
                  required
                >
                  <option value="monthly">Monthly</option>
                  <option value="bi-weekly">Bi-weekly</option>
                  <option value="accelerated-bi-weekly">Accelerated Bi-weekly</option>
                </select>
              </div>
              <div className="form-group">
                <div className="label-container">
                  <label className="label-text" htmlFor="newInterestRate">
                    New Interest Rate (%):
                  </label>
                  <span
                    className="tooltip-trigger"
                    onClick={() =>
                      setActiveTooltip(
                        activeTooltip === "newInterestRate" ? null : "newInterestRate"
                      )
                    }
                    aria-label="Show more information"
                  >
                    What's this?
                    {activeTooltip === "newInterestRate" && (
                      <Tooltip
                        content={tooltipMessages.newInterestRate}
                        isVisible={true}
                        onClose={() => setActiveTooltip(null)}
                      />
                    )}
                  </span>
                </div>
                <input
                  type="number"
                  id="newInterestRate"
                  value={newInterestRate}
                  onChange={(e) => setNewInterestRate(e.target.value)}
                  step="0.01"
                  required
                  min="0"
                  max="100"
                />
              </div>
              <div className="form-group">
                <div className="label-container">
                  <label className="label-text" htmlFor="amortizationPeriod">
                    Mortgage Amortization Period:
                  </label>
                  <span
                    className="tooltip-trigger"
                    onClick={() =>
                      setActiveTooltip(
                        activeTooltip === "amortizationPeriod" ? null : "amortizationPeriod"
                      )
                    }
                    aria-label="Show more information"
                  >
                    What's this?
                    {activeTooltip === "amortizationPeriod" && (
                      <Tooltip
                        content={tooltipMessages.amortizationPeriod}
                        isVisible={true}
                        onClose={() => setActiveTooltip(null)}
                      />
                    )}
                  </span>
                </div>
                <select
                  id="amortizationPeriod"
                  value={amortizationPeriod}
                  onChange={(e) => setAmortizationPeriod(e.target.value)}
                  required
                >
                  {Array.from({ length: 26 }, (_, i) => i + 5).map((years) => (
                    <option key={years} value={years}>
                      {years} years
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <button type="submit" className="submit-button">
              Calculate
            </button>
          </form>
          {result && (
            <div className="results-container">
              <div
                className={`recommendation ${
                  result.interestSavings -
                    Math.max(result.threeMonthPenalty, result.interestDifferentialPenalty) >
                  0
                    ? "positive"
                    : "negative"
                }`}
              >
                <h3 className="animate-pop-in">
                  {result.interestSavings -
                    Math.max(result.threeMonthPenalty, result.interestDifferentialPenalty) >
                  0
                    ? "Breaking your mortgage could save you money!"
                    : "It's likely better to keep your current mortgage."}
                </h3>
                <p className="animate-fade-in">
                  {result.interestSavings -
                    Math.max(result.threeMonthPenalty, result.interestDifferentialPenalty) >
                  0
                    ? `Switching could save $${(
                        result.interestSavings -
                        Math.max(result.threeMonthPenalty, result.interestDifferentialPenalty)
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}!`
                    : `Switching would lose $${Math.abs(
                        result.interestSavings -
                          Math.max(result.threeMonthPenalty, result.interestDifferentialPenalty)
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}.`}
                </p>
              </div>

              <div className="details animate-slide-in">
                <h3>Breakdown:</h3>
                <p>
                  Interest Savings:{" "}
                  <span className="highlight positive">
                    $
                    {result.interestSavings.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </p>
                <p>Breaking penalty is the larger of:</p>
                <p>
                  - Three Month Interest Penalty:{" "}
                  <span className="highlight negative">
                    $
                    {result.threeMonthPenalty.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                  {result.threeMonthPenalty >= result.interestDifferentialPenalty && (
                    <span className="tag">selected</span>
                  )}
                </p>
                <p>
                  - Interest Rate Differential Penalty:{" "}
                  <span className="highlight negative">
                    $
                    {result.interestDifferentialPenalty.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                  {result.interestDifferentialPenalty > result.threeMonthPenalty && (
                    <span className="tag">selected</span>
                  )}
                </p>
                <p>
                  {result.interestSavings -
                    Math.max(result.threeMonthPenalty, result.interestDifferentialPenalty) >
                  0
                    ? "Net Benefit"
                    : "Net Loss"}
                  :{" "}
                  <span
                    className={`highlight ${
                      result.interestSavings -
                        Math.max(result.threeMonthPenalty, result.interestDifferentialPenalty) >
                      0
                        ? "positive"
                        : "negative"
                    }`}
                  >
                    $
                    {Math.abs(
                      result.interestSavings -
                        Math.max(result.threeMonthPenalty, result.interestDifferentialPenalty)
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </p>
              </div>

              {/* <p className="animate-fade-in">
                It will take{" "}
                {result.breakEvenTimeMonths.toLocaleString("en-US", { maximumFractionDigits: 1 })}{" "}
                months / {result.breakEvenTimePayments.toLocaleString("en-US")}{" "}
                {getPaymentPeriodLabel(paymentFrequency)} payments for the penalty cost to be
                recovered.
              </p> */}
              <div className="details">
                <h3>Additional Information:</h3>
                <p>
                  Current mortgage interest remaining: $
                  {result.currentMortgageInterest.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
                <p>
                  New mortgage interest over the same time: $
                  {result.newMortgageInterest.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>
            </div>
          )}
          <div className="disclaimer-container">
            <div className="disclaimer">
              <h3>Disclaimer</h3>
              <p>
                This calculator is for informational purposes only and does not constitute financial
                advice. Results are based on provided information and general assumptions which may
                not reflect your specific situation. Actual costs and benefits may vary
                significantly. Please consult with your financial advisor, mortgage professional, or
                lender before making any decisions.
                <strong>
                  {" "}
                  Use of this calculator is at your own risk, and we accept no responsibility for
                  decisions made based on these results.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
