export const tooltipMessages = {
  remainingBalance: "The current unpaid principal amount of your mortgage. You can find this on your mortgage statement or by logging into your online banking.",
  remainingTerm: "The number of months left until your mortgage term ends (not the amortization). Check your mortgage statement or agreement for this date.",
  currentInterestRate: "Your current mortgage interest rate after any discounts. This rate appears on your mortgage statement or agreement.",
  breakingInterestRate: "The posted (non-discounted) rate for your current mortgage term. Check your agreement or contact your lender for this rate.",
  currentProviderPrimeRate: "Your lender's current prime rate. This can be found on your lender's website or by contacting them directly.",
  periodicPayment: "The amount you pay each payment period (monthly, bi-weekly, etc.). Check your mortgage statement or payment history.",
  paymentFrequency: "How often you make mortgage payments. This is specified in your mortgage agreement.",
  newInterestRate: "The interest rate offered by the new lender or for the new term you're considering.",
  amortizationPeriod: "The total length of time it will take to pay off your mortgage completely. This was set when you first got your mortgage."
};
