import React, { useEffect, useRef, useState } from 'react';

interface TooltipProps {
  content: string;
  isVisible: boolean;
  onClose: () => void;
}

const Tooltip: React.FC<TooltipProps> = ({ content, isVisible, onClose }) => {
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isVisible) {
        onClose();
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isVisible) {
      document.addEventListener('keydown', handleEscKey);
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('keydown', handleEscKey);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible, onClose]);

  if (!isVisible) return null;

  return (
    <div className="tooltip" ref={tooltipRef}>
      <button className="tooltip-close" onClick={onClose} aria-label="Close tooltip">
        ×
      </button>
      <div className="tooltip-content">{content}</div>
    </div>
  );
};

export default Tooltip;
